.container{
  padding: 0 10px;
}


.nav-area {
  display: flex;
  align-items: center;
  /* max-width: 1200px; */
  margin: 0 auto;
  padding: 10px 20px;
}

.logo {
  text-decoration: none;
  font-size: 25px;
  color: inherit;
  margin-right: 20px;
}

.menus {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
}

.menu-items {
  position: relative;
  font-size: 14px;
}

.menu-items a {
  display: block;
  font-size: inherit;
  color: inherit;
  text-decoration: none;
}

.menu-items button {
  display: flex;
  align-items: center;
  color: inherit;
  font-size: inherit;
  border: none;
  background-color: transparent;
  cursor: pointer;
  width: 100%;
}

button span {
  margin-left: 3px;
}

.menu-items > a, .menu-items button {
  text-align: left;
}

.arrow::after {
  content: "";
  display: inline-block;
  margin-left: 0.28em;
  vertical-align: 0.09em;
  border-top: 0.42em solid;
  border-right: 0.32em solid transparent;
  border-left: 0.32em solid transparent;
}

.dropdown {
  position: absolute;
  right: 0;
  box-shadow: 0 0 4px 0px #fff;
  font-size: 0.875rem;
  z-index: 9999;
  min-width: 16rem;
  padding: 0.5rem 0;
  list-style: none;
  background-color: #fff;
  border-radius: 0.5rem;
  display: none;
  background-color: #000;
}

.dropdown.show {
  display: block;
}

@media only screen and (max-width: 1023px) {
  .dropdown.show {
    display: block;
  }
  .dropdown {
    left: 0;
  }
  .menu-items a {
    width: 100% !important;
  }
}

.dropdown .dropdown-submenu {
  position: absolute;
  left: 100%;
  top: -7px;
}

.vertical-timeline-element-content {
  background-color: #77c1dd14 !important;
}



.vertical-timeline-element-content h2 {
  color: #77c1dd !important;
  font-size: 1.8rem !important;
}

@media only screen and (max-width: 768px) {
  .vertical-timeline-element-content h2 {
    font-size: 1.2rem !important;
  }
}

.vertical-timeline--two-columns .vertical-timeline-element-icon {
  top: 40%;
}

@tailwind base;
@tailwind components;
@tailwind utilities;  

.bn5 {
  padding: 8px;
  border: none;
  outline: none;
  color: rgb(255, 255, 255);
  background: #111;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
}

.bn5:before {
  content: "";
  background: linear-gradient(
    45deg,
    #ff7e79,
    #58f658,
    #d00241,
    #ff9e98,
    #6193ff,
    #a51bff,
    #93d6f8,
    #00ce8d,
    #ff5453
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowingbn5 20s linear infinite;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

@keyframes glowingbn5 {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.bn5:active {
  color: #000;
}

.bn5:active:after {
  background: #191919;
}

.bn5:hover:before {
  opacity: 1;
}

.bn5:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #191919;
  left: 0;
  top: 0;
  border-radius: 10px;
}

.bn6 {
  /* padding: 8px; */
  color: rgb(255, 255, 255);
  background: #111;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
}

.bn6:before {
  content: "";
  background: linear-gradient(
    45deg,
    #ff7e79,
    #58f658,
    #d00241,
    #ff9e98,
    #6193ff,
    #a51bff,
    #93d6f8,
    #00ce8d,
    #ff5453
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowingbn5 20s linear infinite;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

.bn6:active {
  color: #000;
}

.bn6:active:after {
  background: #191919;
}

.bn6:before {
  opacity: 1;
}

.bn6:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #191919;
  left: 0;
  top: 0;
  border-radius: 10px;
}

.pagination li.active {
  background-color: white;
  color: #fff; 
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(124 124 124 / 60%); 
  backdrop-filter: blur(5px); 
  z-index: 9;
}

.custom-shape-divider-bottom-1690790726 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.custom-shape-divider-bottom-1690790726 svg {
    position: relative;
    display: block;
    width: calc(126% + 1.3px);
    height: 238px;
}

.custom-shape-divider-bottom-1690790726 .shape-fill {
    fill: #FFFFFF;
}

/* Add this CSS to your stylesheet */
/* Add this CSS to your stylesheet */
.connecting-lines {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.line {
  position: absolute;
  background-color: white;
  z-index: -1;
}

.horizontal-line {
 width: 1px;
    border: 2px dashed;
    height: 100%;
    top: 50%;
    left: 26%;
    transform: translate(-50%, -50%);
}

.vertical-line {
  width: 1px;
    border: 2px dashed;
  height: 100%;
  top: 50%;
  right: 20%;
  transform: translate(-50%, -50%);
}

.vertical1-line {
 width: 50%;
    height: 1px;
    bottom: 10%;
    left: 50%;
   border: 2px dashed; 
    transform: translate(-50%, -50%);
}

/* styles.css or your preferred stylesheet */
/* styles.css or your preferred stylesheet */
@keyframes slideUp {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.animate-slide-up {
  animation: slideUp 0.5s ease-in-out;
}

